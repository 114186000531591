.Credits {
  font-size: 1.3rem;
  text-align: center;
  padding-bottom: 1rem;
  margin-top: 1rem;
  font-weight: bold;

  .separator {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .Credits {
    font-size: 1rem;

    span::after {
      content: '\A';
      white-space: pre;
    }
  }
}
