@import './colors.scss';

@font-face {
  font-family: 'Montserrat-Medium';
  src: url(./fonts/Montserrat/Montserrat-Medium.ttf);
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url(./fonts/Montserrat/Montserrat-Light.ttf);
}

body {
  margin: 0;
  font-family: 'Montserrat-Light', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $green-text !important;
  background-color: $background !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-image: url('./img/67062.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 25%;
  overflow: auto;
}
@media (min-width: 768px) {
  #root {
    background-image: url('./img/67062.jpg');
    background-repeat: no-repeat;
    background-size: 50%;
    padding-top: 0;
    overflow: auto;
  }
}
@media (min-width: 1480px) {
  #root {
    background-image: url('./img/top-view-green-leaves.jpg');
    background-repeat: no-repeat;  
    background-attachment: fixed;
    background-size: cover;
  }
}