@import '../colors.scss';

.Main {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: bold;

  .Main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 6rem;
    padding-left: 6rem;
    max-width: 70%;

    .logo-container {
      margin-top: 3rem;
    }

    .Main-title {
      line-height: 4rem;
      font-family: 'Montserrat-Light';
      color: $green-text !important;
      font-size: 5rem;
      text-transform: uppercase;
      margin-bottom: 0px;
    }

    .Main-and {
      color: $brown-text;
      font-size: 3rem;
      margin-top: 0em;
      margin-bottom: 0em;
    }

    .content {
      h1 {
        color: $brown-text;
        font-size: 3.2rem;
      }
    }

    .Landing-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .Landing-picture {
        border-radius: 5%;
        width: 60%;
      }

      i {
        font-size: 1.2rem;
        margin-bottom: 2em;
      }
    }
  }

  p {
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .extraText {
    color: $brown-text;
    font-size: 1.1rem;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 8px;
    letter-spacing: 2px;
  }

  .subText {
    color: $green-text;
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .extraText {
    max-width: 50%;
    font-weight: bold;
  }

  .Main {
    .Main-container {
      border-radius: 91px;
      padding-right: 2rem;
      padding-left: 2rem;
      width: 90%;
      max-width: unset;

      .Main-title {
        line-height: 2rem;
        font-size: 4rem;
        text-transform: uppercase;
        margin-bottom: 0px;
      }

      .logo-container {
        margin-bottom: 3rem;
      }

      .Landing-picture {
        width: 100% !important;
      }
    }
  }
}
